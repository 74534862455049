<template>
  <!-- 当前选中图层操作器 -->
  <div class="lineOperatorsComponent hook-lineOperators-component">
    <div class="operation-wrapper">
      <div
        :class="['operation-item', ...classNameList]"
        :key="index"
        v-for="({ title, list, scaleMin, classNameList }, operationKey, index) in operation"
      >
        <div class="title">{{ title }}</div>
        <el-row :gutter="5" class="list">
          <template v-for="({ icon, operatorType, tooltipText, slotName }, indx) in list">
            <el-col :span="4" :key="indx" v-if="!slotName">
              <div class="col-wrapper">
                <el-tooltip effect="dark" :content="tooltipText" placement="bottom-start">
                  <div @click="operatorHandler(operatorType, operatorType == 'clear')" :class="['icon-wrapper']">
                    <i :class="['iconfont', icon, scaleMin && 'min-scale']" />
                  </div>
                </el-tooltip>
              </div>
            </el-col>

            <el-col :span="24" :key="indx" v-else-if="slotName == 'angleSlot'">
              <LineOperatorItem prop="angle" />
            </el-col>

            <el-col :span="24" :key="indx" v-else-if="slotName == 'scaleXSlot'">
              <LineOperatorItem prop="scaleX" />
            </el-col>

            <el-col :span="24" :key="indx" v-else-if="slotName == 'scaleYSlot'">
              <LineOperatorItem prop="scaleY" />
            </el-col>
          </template>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import LineOperatorItem from './lineOperatorItem'

import { fabricInstanceMixin } from '../../../../mixins'
import { createRandomNum } from '@/utils'
import { DESIGN_SHOW_AREA_W, DESIGN_SHOW_AREA_H } from '@/utils/constant'

export default {
  inject: ['canvasModifiedHandler'],
  mixins: [fabricInstanceMixin],
  components: {
    LineOperatorItem
  },

  data() {
    return {
      angle: 0,
      operation: {
        scaleX: {
          title: '宽度',
          classNameList: ['operation-item-flex'],
          list: [{ slotName: 'scaleXSlot' }]
        },
        scaleY: {
          title: '高度',
          classNameList: ['operation-item-flex'],
          list: [
            {
              slotName: 'scaleYSlot'
            }
          ]
        },
        rotate: {
          title: '旋转',
          classNameList: ['operation-item-flex'],
          scaleMin: true,
          list: [
            {
              slotName: 'angleSlot'
            }
          ]
        },
        order: {
          title: '顺序',
          list: [
            {
              icon: 'iconshejiqi_dingceng',
              operatorType: 'toTopLayer',
              tooltipText: '置于顶部'
            },
            {
              icon: 'iconshejiqi_diceng',
              operatorType: 'toBottomLayer',
              tooltipText: '置于底部'
            },
            {
              icon: 'iconshejiqi_shangyiceng',
              operatorType: 'lastLayer',
              tooltipText: '上移'
            },
            {
              icon: 'iconshejiqi_xiayiceng',
              operatorType: 'nextLayer',
              tooltipText: '下移'
            },

            {
              icon: 'iconshejiqi_fuzhituceng',
              operatorType: 'clone',
              tooltipText: '复制'
            },
            {
              icon: 'iconshejiqi_shanchutuceng',
              operatorType: 'delete',
              tooltipText: '删除'
            }
          ]
        },
        align: {
          title: '对齐',

          list: [
            {
              icon: 'iconshejiqi_zuoduiqi',
              operatorType: 'alignLeft',
              tooltipText: '左对齐'
            },
            {
              icon: 'iconshejiqi_shuipingjuzhong-23',
              operatorType: 'alignHorizontal',
              tooltipText: '水平对齐'
            },
            {
              icon: 'iconshejiqi_youduiqi',
              operatorType: 'alignRight',
              tooltipText: '右对齐'
            },

            {
              icon: 'iconshejiqi_dingduiqi',
              operatorType: 'alignTop',
              tooltipText: '上对齐'
            },
            {
              icon: 'iconshejiqi_chuizhijuzhong-26',
              operatorType: 'alignVertical',
              tooltipText: '垂直对齐'
            },
            {
              icon: 'iconshejiqi_diduiqi',
              operatorType: 'alignBottom',
              tooltipText: '下对齐'
            }
          ]
        },

        // adapt: {
        //   title: '适应',
        //   list: [
        //     {
        //       icon: 'icon-shejiqi_wuxiaoguo',
        //       operatorType: 'adapt',
        //       tooltipText: '适应'
        //     },
        //     {
        //       icon: 'icon-shejiqi_puman',
        //       operatorType: 'fill',
        //       tooltipText: '铺满'
        //     },
        //     {
        //       icon: 'icon-shejiqi_shuipingjuzhong-44',
        //       operatorType: 'scaleToMaxWidth',
        //       tooltipText: '宽度最大'
        //     },
        //     {
        //       icon: 'icon-shejiqi_chuizhijuzhong-43',
        //       operatorType: 'scaleToMaxHeight',
        //       tooltipText: '高度最大'
        //     },
        //     {
        //       icon: 'icon-shejiqi_huanyuan',
        //       operatorType: 'stretch',
        //       tooltipText: '拉伸'
        //     }
        //   ]
        // }
      }
    }
  },

  watch: {
    curFabircActiveObjectAngle: {
      handler(newVal) {
        this.angle = (+newVal || 0).toFixed(2)
      },
      immediate: true
    }
  },

  computed: {
    canvasWidth() {
      return this.instance.width
    },

    canvasHeight() {
      return this.instance.height
    }
  },

  methods: {
    formatTooltip(value) {
      return +(value || 0).toFixed(2)
    },

    watchRotate() {
      const objects = this.fabricObjects || []
      if (objects.length > 0) {
        const activeObject = this.getActiveObject()
        if (activeObject) {
          this.angle = parseInt(activeObject.angle || 0)
        }
      } else {
        this.angle = 0
      }
    },

    operatorHandler(type, bool) {
      const activeObject = this.getActiveObject()
      if (!activeObject && !bool) {
        this.$message.warning('请先选中需要操作的图层')
        return
      }
      const funcObj = {
        //顺序列表操作
        toTopLayer: this.toTopLayerHandler, //顶层
        toBottomLayer: this.toBottomLayerHandler, //底层
        lastLayer: this.lastLayerHandler, //上一层
        nextLayer: this.nextLayerHandler, //下一层
        clone: this.cloneHandler, //复制
        delete: this.deleteHandler, //删除

        //对齐
        alignLeft: this.alignLeftHandler, //左对齐
        alignHorizontal: this.alignHorizontalHandler, //水平对齐
        alignRight: this.alignRightHandler, //右对齐
        alignTop: this.alignTopHandler, //上对齐
        alignVertical: this.alignVerticalHandler, //垂直对齐
        alignBottom: this.alignBottomHandler, //下对齐

        //镜像
        flipX: this.flipXHandler,
        flipY: this.flipYHandler,

        //适应
        adapt: this.adaptHandler,
        fill: this.fillHandler,
        scaleToMaxWidth: this.scaleToMaxWidthHandler,
        scaleToMaxHeight: this.scaleToMaxHeightHandler,
        stretch: this.stretchHandler
      }
      funcObj[type](activeObject)
      activeObject.setCoords()
      this.instance.renderAll()
      this.rander()
    },

    rander() {
      this.canvasModifiedHandler()
    },

    //图案上移一层
    lastLayerHandler(activeObject) {
      this.instance.toLastLayer(activeObject)
    },
    //图案下移一层
    nextLayerHandler(activeObject) {
      this.instance.toNextLayer(activeObject)
    },
    //顶层
    toTopLayerHandler(activeObject) {
      this.instance.toTopLayer(activeObject)
    },
    //底层
    toBottomLayerHandler(activeObject) {
      this.instance.toBottomLayer(activeObject)
    },

    //左对齐
    alignLeftHandler(activeObject) {
      const { l, boundingRect } = this.getLeftAndTopAndBoundingRect(activeObject)
      const { min } = Math
      const xMinPos = min(...boundingRect.map((p) => p.x))
      activeObject.setOptions({
        left: activeObject.left - (xMinPos - l)
      })
      this.instance.renderAll()
    },

    //水平对齐
    alignHorizontalHandler(activeObject) {
      this.instance.centerH(activeObject)
      this.instance.renderAll()
    },

    //右对齐
    alignRightHandler(activeObject) {
      const { r, boundingRect } = this.getLeftAndTopAndBoundingRect(activeObject)
      const { max } = Math
      const xMinPos = max(...boundingRect.map((p) => p.x))
      activeObject.setOptions({
        left: activeObject.left - (xMinPos - r)
      })
      this.instance.renderAll()
    },

    //上对齐
    alignTopHandler(activeObject) {
      const { t, boundingRect } = this.getLeftAndTopAndBoundingRect(activeObject)
      const { min } = Math
      const yMinPos = min(...boundingRect.map((p) => p.y))
      activeObject.setOptions({
        top: activeObject.top - (yMinPos - t)
      })
      this.instance.renderAll()
    },

    //竖直对齐
    alignVerticalHandler(activeObject) {
      this.instance.centerV(activeObject)
      this.instance.renderAll()
    },

    //下对齐
    alignBottomHandler(activeObject) {
      const { b, boundingRect } = this.getLeftAndTopAndBoundingRect(activeObject)
      const { max } = Math
      const yMinPos = max(...boundingRect.map((p) => p.y))
      activeObject.setOptions({
        top: activeObject.top - (yMinPos - b)
      })
      this.instance.renderAll()
    },

    //镜像

    //水平镜像
    flipXHandler(activeObject) {
      activeObject.setOptions({
        flipX: !activeObject.flipX
      })
      // this.instance.flipX(activeObject)
      this.instance.renderAll()
    },
    //垂直镜像
    flipYHandler(activeObject) {
      this.instance.flipY(activeObject)
      this.instance.renderAll()
    },

    //适应

    //适应
    adaptHandler(activeObject) {
      const { width, height } = activeObject,
        { min } = Math
      const needScaleX = this.canvasWidth / width
      const needScaleY = this.canvasHeight / height
      const minScale = min(needScaleX, needScaleY)
      activeObject.setOptions({
        scaleX: minScale,
        scaleY: minScale
      })

      this.instance.renderAll()
      //更新缩放
      this.updateScale(activeObject)
    },

    //填满
    fillHandler(activeObject) {
      const { width, height } = activeObject,
        { max } = Math
      const needScaleX = this.canvasWidth / width
      const needScaleY = this.canvasHeight / height
      const maxScale = max(needScaleX, needScaleY)
      activeObject.setOptions({
        scaleX: maxScale,
        scaleY: maxScale
      })
      this.instance.renderAll()
      //更新缩放
      this.updateScale(activeObject)
    },

    //拉伸
    stretchHandler(activeObject) {
      const { width, height } = activeObject
      activeObject.setOptions({
        scaleX: this.canvasWidth / width,
        scaleY: this.canvasHeight / height
      })
      this.instance.renderAll()
      //更新缩放
      this.updateScale(activeObject)
    },

    //宽度最大
    scaleToMaxWidthHandler(activeObject) {
      activeObject.scaleToWidth(this.canvasWidth)
      this.instance.renderAll()
      //更新缩放
      this.updateScale(activeObject)
    },

    //高度最大
    scaleToMaxHeightHandler(activeObject) {
      activeObject.scaleToHeight(this.canvasHeight)
      this.instance.renderAll()
      //更新缩放
      this.updateScale(activeObject)
    },

    //复制图案
    cloneHandler(activeObject) {
      this.instance.clone(
        activeObject,
        (o) => {
          const objects = this.instance.getObjects()
          const activeObjectIndex = objects.findIndex(({ id }) => id === activeObject.id)
          o.left = o.left + 20
          o.top = o.top + 20
          this.instance.add(o)
          const splitIds = activeObject.id.split('@').slice(1)
          o.setOptions({
            id: createRandomNum() + '@' + splitIds.join('@')
          })
          o.moveTo(activeObjectIndex + 1)
          this.instance.renderControls(o)
          this.instance.renderAll()
        },
        {}
      )
    },

    //删除当前图层
    async deleteHandler(activeObject) {
      this.instance.remove(activeObject)
    },

    //更新缩放
    updateScale(activeObject) {
      this.UPDATE_CUR_FABRIC_ACTIVE_OBJECT_SCALE({
        scaleX: activeObject.scaleX,
        scaleY: activeObject.scaleY
      })
    },

    //更新旋转角度
    updateAngle(activeObject) {
      this.UPDATE_CUR_FABRIC_ACTIVE_OBJECT_ANGLE(activeObject.angle)
    },

    //获取宽度高度和旋转之后的x,y列表
    getLeftAndTopAndBoundingRect(activeObject) {
      const [realWidth, realHeight] = [this.canvasWidth, this.canvasHeight]

      const { t, l } = this.getClipPos({
        rW: realWidth,
        rH: realHeight,
        cW: DESIGN_SHOW_AREA_W,
        cH: DESIGN_SHOW_AREA_H
      })
      const boundingRect = this.getRectPosByAngle(activeObject)
      return {
        t,
        l,
        r: l + realWidth,
        b: t + realHeight,
        boundingRect
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.lineOperatorsComponent {
  .operation-wrapper {
    .operation-item {
      .title {
        line-height: 40px;
        margin-right: 15px;
        white-space: nowrap;
        font-size: 12px;
        color: #9da3ac;
      }
    }
    .operation-item-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        align-self: stretch;
      }
    }
  }
  .list {
    flex: 1;
    border-radius: 4px;
    width: auto;
    .col-wrapper,
    .angle-col-wrapper {
      position: relative;
      padding-top: 100%;
    }

    .icon-wrapper {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-gray;
      font-size: 14px;
      border: 1px solid #dcdee0;
      color: $color-gray;
      border-radius: 3px;
      // max-width: 40px;
      // max-height: 40px;
      i {
        display: inline-block;
        transform: scale(1.5);
        font-size: 1vw;
      }
      .min-scale {
        transform: scale(1);
      }
    }
    .icon-wrapper.active {
      color: $color-gray;
    }
    .item,
    .angle-item {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 4px;
      border: none;
      width: 100%;
    }
    .angle-col-wrapper {
      padding-top: 50%;
      width: 100%;
    }
    .angle-item {
      border: none;
    }

    .tile-slider-angle-item {
      width: 100%;
    }
  }
}
</style>
